<template>
  <div class="alert-viewer" v-if="alert !== null">
    <h1 class="title">
      {{ alert.name }}
      <span class="ti-tag devicetype">{{ alert.devicetype }}</span>
      <span v-if="alert.tags.include !== undefined">
        <span v-for="tag in alert.tags.include" :key="tag" class="ti-tag">{{ tag }}</span>
      </span>
      <span v-if="alert.tags.exclude !== undefined">
        <span v-for="tag in alert.tags.exclude" :key="tag" class="ti-tag exclude">{{ tag }}</span>
      </span>
    </h1>
    <grid>
      <grid-col size="6">
        <card title="Alert Information">
          <div class="property">
            <div class="title">Available</div>
            <i v-if="alert.available" class="uil uil-check-circle green" />
            <i v-else class="uil uil-check-circle red" />
          </div>
          <TextProperty title="Created" :value="$date(alert.createdAt).format('LLL')" />
          <TextProperty
            title="Check Interval"
            :value="'every ' + duration(alert.interval * 1000)"
          />
          <TextProperty
            title="Last checked"
            :value="alert.lastChecked ? $date(alert.lastChecked).fromNow() : 'never'"
          />
          <TextProperty
            title="Last triggered"
            :value="alert.lastTriggered ? $date(alert.lastTriggered).fromNow() : 'never'"
          />
          <hr class="spacing" />
          <router-link
            v-if="isAdmin() || userCan('edit', 'alert')"
            :to="{ name: 'alertEdit', params: { alert: alert._id } }"
          >
            <base-button :isSmall="true" type="THEMED" iconType="EDIT" title="Edit" />
          </router-link>

          <base-button
            v-if="isAdmin() || userCan('delete', 'alert')"
            :isSmall="true"
            type="THEMED"
            iconType="TRASH"
            title="Delete"
            v-on:click="deleteAlert"
          />

          <base-button
            v-if="isAdmin() || userCan('edit', 'alert')"
            :isSmall="true"
            type="THEMED"
            :iconType="alert.available ? 'CANCEL' : 'CHECK'"
            :title="alert.available ? 'Disable' : 'Enable'"
            v-on:click="toggleAlert"
          />
          <base-button
            v-if="isAdmin() || userCan('edit', 'alert')"
            :isSmall="true"
            type="THEMED"
            title="Match"
            v-on:click="matchAlert"
            ><i class="uil uil-file-search-alt"
          /></base-button>
        </card>
      </grid-col>
      <grid-col size="6">
        <card title="Recipients">
          <h4 v-if="!alert.recipients.length">No recipients configured</h4>
          <ul v-for="recipient in alert.recipients" :key="recipient._id" class="recipients">
            <li>{{ recipient.name }}</li>
            <ul v-for="target of recipient.targets" :key="JSON.stringify(target.target)">
              <li>
                <i v-if="target.type === 'slack'" class="uil uil-slack green" />
                <i v-if="target.type === 'mailto'" class="uil uil-envelope blue" />
                <i v-if="target.type === 'url'" class="uil uil-link orange" />
                <i v-if="target.type === 'csv'" class="uil uil-file-alt orange" />
                <span v-if="target.type === 'csv'">{{ target.target.uri }}</span>
                <span v-else>{{ target.target }}</span>
              </li>
            </ul>
          </ul>
        </card>
        <card title="Rules">
          <div v-if="alert.rules.rules !== undefined && alert.rules.rules.length > 0">
            <ul v-for="rule in alert.rules.rules" :key="rule.id" class="recipients">
              <li>{{ rule.id }} = {{ rule.value }}</li>
            </ul>
          </div>
          <div v-else-if="alert.onlyPower">Only power status will be checked</div>
          <div v-else>There are no rules defined for this alert</div>
        </card>
      </grid-col>
    </grid>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>
<script>
import Utils from '@/utils';
import BaseComponent from '../Base.vue';
import TextProperty from '../Property/TextProperty.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'singlealert',
  extends: BaseComponent,
  components: {
    TextProperty,
    Card,
    Grid,
    GridCol,
    BaseButton,
  },
  props: ['alertId'],
  watch: {
    alertId(newVal) {
      this.getalert(newVal);
    },
  },
  data() {
    return {
      ajaxCompleted: false,
      alert: null,
    };
  },
  methods: {
    duration(ms) {
      return Utils.duration(ms);
    },
    userCan(...args) {
      return Utils.userCan(...args);
    },
    async getalert(id) {
      const response = await Utils.fetch(`/api/v1/alerts/${id}`, {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.ajaxCompleted = true;
        this.alert = response.alert;
      }
    },
    async deleteAlert() {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Do you really want to delete this alert and all its history?')) {
        const response = await Utils.fetch(
          `/api/v1/alerts/${this.alertId}`,
          {
            method: 'DELETE',
          },
          this,
        ).then((res) => res.json());
        if (response.success) {
          this.$noty.success(`Successfully deleted the alert '${this.alert.name}' from Wherever.`);
          this.$emit('deleted');
          this.$router.push('/alerts/manager/');
        } else {
          this.$noty.warning(response.message);
        }
      }
    },
    async toggleAlert() {
      this.ajaxCompleted = false;
      const response = await Utils.fetch(
        `/api/v1/alerts/toggle/${this.alertId}`,
        {},
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.alert.available = response.alert.available;
        this.$noty.success(`Toggled the alert '${this.alert.name}'`);
        this.$root.$emit('reloadTable');
      } else {
        this.$noty.warning(response.message);
      }
      this.ajaxCompleted = true;
    },
    async matchAlert() {
      const response = await Utils.fetch(`/api/v1/alerts/match/${this.alertId}`).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.$noty.success(`Started match job for the alert '${this.alert.name}'`);
      } else {
        this.$noty.warning('Error starting job');
      }
    },
  },
  mounted() {
    this.getalert(this.alertId);
  },
};
</script>

<style lang="scss" scoped>
h1.title span.ti-tag {
  background-color: #f8b36b;
  color: #1e2a31;
  border-radius: 2px;
  display: inline-block;
  padding: 3px 5px;
  margin: 2px;
  font-size: 11px;
  top: -5px;
  position: relative;

  &:first-child {
    margin-left: 10px;
  }

  &.exclude {
    background-color: #4f3401;
    border: 1px solid #f8b36b;
    color: #f8b36b;
  }
}

h1.title span.ti-tag.devicetype {
  background-color: #d28800;
}

h1.title span.ti-tag.devicetype:before {
  content: '';
}

.actions a {
  margin-left: 5px;
}

.spacing {
  padding-bottom: 15px;
}

.button {
  display: block;
  float: left;
  margin: 2px;
}

.themed-match {
  background: rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.6);

  &:hover {
    background: rgb(35, 55, 128) !important;
    color: rgba(255, 255, 255, 1);
  }
}
ul.recipients,
ul.recipients ul {
  padding-inline-start: 20px;
}
</style>
