<template>
  <div>
    <query-builder-group />
  </div>
</template>

<script>
import QueryBuilderGroup from './QueryBuilderGroup.vue';

export default {
  components: { QueryBuilderGroup },
  name: 'QueryBuilder',
  props: ['value', 'deviceType'],
  watch: {
    deviceType() {
      this.$store.dispatch('alertQuery/getAvailableFilters', this.deviceType);
    },
  },
};
</script>
