<template>
  <div class="alerts">
    <panes>
      <template v-slot:left>
        <div class="alert-list">
          <h1>
            Alert Manager
            <base-button
              v-on:click="createAlert"
              type="THEMED"
              iconType="PLUS"
              title="Create new alert"
              class="right"
            />
          </h1>
          <AlertTable v-on:rowClick="handleRowClick" :savedAt="savedAt" />
        </div>
      </template>
      <template v-slot:right>
        <div class="alert-view">
          <AlertCreateEdit
            v-if="['alertEdit'].indexOf($route.name) > -1 && typeof selected === 'string'"
            :alert-id="selected"
            v-on:saved="handleSaved"
          />
          <AlertCreateEdit
            v-else-if="create === true"
            v-on:cancel="create = false"
            :alert-id="-1"
            v-on:saved="handleSaved"
          />
          <AlertRead
            v-else-if="selected !== null && typeof selected === 'string' && create === false"
            :alert-id="selected"
            v-on:deleted="handleDeleted"
          />
        </div>
      </template>
    </panes>
  </div>
</template>

<script>
import AlertTable from '../components/Table/Alert.vue';
import AlertRead from '../components/AlertManager/Read.vue';
import AlertCreateEdit from '../components/AlertManager/CreateEdit.vue';
import Panes from '../components/Panes/Panes.vue';
import BaseButton from '../components/BaseButton/BaseButton.vue';

export default {
  name: 'Alert',
  components: {
    AlertTable,
    AlertRead,
    AlertCreateEdit,
    Panes,
    BaseButton,
  },
  data() {
    return {
      selected: null,
      create: false,
      savedAt: null,
    };
  },
  methods: {
    handleRowClick(id) {
      this.create = false;
      this.selected = id;
    },
    handleSaved() {
      // this.savedAt = Date.now();
      this.$root.$emit('reloadTable');
    },
    handleDeleted() {
      this.handleSaved();
      this.selected = null;
    },
    createAlert() {
      this.create = true;
      this.$router.replace('/alerts/manager/create');
    },
  },
  mounted() {
    if (typeof this.$route.params.alert !== 'undefined') {
      if (this.$route.params.alert === 'create') {
        this.create = true;
      } else {
        this.selected = this.$route.params.alert;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.alerts {
  height: 100%;
}

.alert-list {
  height: 100%;
}

.alert-view {
  height: 100%;
  overflow-x: auto;
}
</style>
