<template>
  <div class="filter-rule">
    <select v-model="selectedFilter" class="input-select">
      <option v-for="filter in availableFilters" :key="filter.id" :value="filter.id">
        {{ filter.label }}
      </option>
    </select>
    <div v-if="selectedFilterObject">
      <select
        v-if="selectedFilterObject.operators.length > 1"
        v-model="createdFilter.operator"
        class="input-select"
      >
        <option v-for="operator in selectedFilterObject.operators" :key="operator">
          {{ operator }}
        </option>
      </select>
      <span v-else>{{ selectedFilterObject.operators[0] }}</span>
      <select
        v-if="selectedFilterObject.input === 'select'"
        v-model="createdFilter.value"
        class="input-select"
      >
        <option v-for="value in selectedFilterObject.values" :key="value">
          {{ value }}
        </option>
      </select>
      <input
        v-else
        :type="selectedFilterObject.type === 'double' ? 'number' : 'text'"
        :min="selectedFilterObject.validation.min"
        :max="selectedFilterObject.validation.max"
        v-model="createdFilter.value"
        @keypress="onlyNumber"
        class="input-text"
      />
    </div>
    <base-button
      isSmall
      type="THEMED"
      iconType="TRASH"
      title="Delete Rule"
      v-on:click="deleteRule(id)"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import BaseButton from '../../BaseButton/BaseButton.vue';

export default {
  components: { BaseButton },
  name: 'QueryBuilderRule',
  props: ['groupId', 'id'],
  data() {
    return {
      createdFilter: {},
      selectedFilter: null,
    };
  },
  computed: {
    ...mapGetters('alertQuery', ['availableFilters']),
    selectedFilterObject() {
      return this.availableFilters.find((filter) => filter.id === this.selectedFilter);
    },
  },

  methods: {
    ...mapMutations('alertQuery', ['deleteRule', 'updateRuleData']),
    onlyNumber($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    validateValue() {
      if (this.selectedFilterObject.validation) {
        const { min } = this.selectedFilterObject.validation;
        const { max } = this.selectedFilterObject.validation;
        if (min && this.createdFilter.value < min) {
          return false;
        }
        if (max && this.createdFilter.value > max) {
          return false;
        }
        return true;
      }
      return true;
    },
  },
  watch: {
    createdFilter: {
      handler() {
        this.updateRuleData({
          ...this.createdFilter,
          id: this.id,
          groupId: this.groupId,
          isValid: this.validateValue(),
        });
      },
      deep: true,
    },
    selectedFilter() {
      this.createdFilter = {
        operator: 'equal',
        filterId: this.selectedFilter,
        type: this.selectedFilterObject.type,
        input: this.selectedFilterObject.input || 'text',
        field: this.selectedFilterObject.field,
      };
    },
  },
};
</script>
<style lang="scss">
.filter-rule {
  display: flex;
  background: rgba(0, 0, 0, 0.1);
  padding: 4px;
  border-radius: 4px;
  margin: 8px 0;
  align-items: center;

  div,
  select,
  input {
    margin: 8px;
  }
}
.query-group-header {
  display: flex;
  align-items: center;
  input {
    margin: 0 8px;
    padding: 0;
  }
  button {
    display: block;
    margin-left: auto;
  }
}
</style>
