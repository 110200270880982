<template>
  <div class="query-group">
    <div class="query-group-header">
      <label for="scopeAnd">AND</label>
      <input type="radio" :name="id" value="AND" id="scopeAnd" v-model="scope" />
      <label for="scopeOr">OR</label>
      <input type="radio" :name="id" id="scopeOr" value="OR" v-model="scope" />
      <div class="button-group">
        <base-button
          isSmall
          type="THEMED"
          iconType="PLUS"
          v-on:click="createRule()"
          title="Add rule"
        />
        <base-button
          isSmall
          type="THEMED"
          iconType="PLUS"
          v-on:click="createGroup()"
          title="Add group"
        />
        <base-button
          isSmall
          type="THEMED"
          iconType="TRASH"
          v-on:click="$emit('delete', id)"
          title="Delete group"
        />
      </div>
    </div>
    <template v-for="queryRule in getRulesByGroupId(id)">
      <query-builder-rule :key="queryRule" :groupId="id" :id="queryRule.id" />
    </template>
    <template v-for="queryGroup in queryGroups">
      <query-builder-group :key="queryGroup.id" :parentId="id" v-on:delete="handleGroupDelete" />
    </template>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { uuid } from 'vue-uuid';
import QueryBuilderRule from './QueryBuilderRule.vue';
import BaseButton from '../../BaseButton/BaseButton.vue';

export default {
  name: 'QueryBuilderGroup',
  components: { QueryBuilderRule, BaseButton },
  props: ['parentId'],

  data() {
    return {
      queryGroups: [],
      scope: 'AND',
    };
  },
  computed: {
    ...mapGetters('alertQuery', ['getRulesByGroupId']),
    id() {
      return uuid.v4();
    },
  },
  methods: {
    ...mapMutations('alertQuery', ['addGroup', 'deleteGroup', 'addRule', 'changeGroup']),
    createGroup() {
      this.queryGroups.push(this.queryGroups.length + 1);
    },
    createRule() {
      this.addRule({
        id: uuid.v4(),
        groupId: this.id,
      });
    },
    handleGroupDelete(groupId) {
      this.deleteGroup(groupId);
      this.queryGroups.splice(groupId - 1, 1);
    },
  },
  watch: {
    scope() {
      this.changeGroup({
        id: this.id,
        parentId: this.parentId,
        scope: this.scope,
      });
    },
  },
  mounted() {
    this.addGroup({ id: this.id, parentId: this.parentId, scope: this.scope });
  },
};
</script>
<style lang="scss">
.query-group {
  padding: 8px;
}
.query-group-header {
  display: flex;
  align-items: center;
  input {
    margin: 0 8px;
    padding: 0;
  }
  .button-group {
    display: flex;
    margin-left: auto;
    align-items: center;
    button {
      margin: 0 4px;
    }
  }
}
</style>
